import React, { Fragment } from "react"
import { Button } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ButtonLink = ({ to, name, icon = null }) => {
  return (
    <Fragment>
      {icon ? (
        <Button to={to}>
          <span>
            <FontAwesomeIcon icon={icon} size="lg" />
            <span>{name}</span>
          </span>
        </Button>
      ) : (
        <Button to={to}>{name}</Button>
      )}
    </Fragment>
  )
}

export default ButtonLink
