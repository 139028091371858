import React from "react"
import { Container, Grid, Icons } from "./styled"

const Two = ({ type, handler, condenser }) => {
  return (
    <Container>
      {(type === "acSplit" || type === "hpSplit") && (
        <Grid className={type}>
          <h3>{`Air Handler · ${handler.model}`}</h3>
          <div>
            <img src={handler.img} alt={handler.model} />
          </div>
          <div>
            <ul>
              {handler.features.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
          <Icons>
            {handler.icons.map((item, index) => {
              return <img key={index} src={`${item}`} alt="Feature Icon" />
            })}
          </Icons>
        </Grid>
      )}
      <Grid>
        <h3>
          {type === "acSplit" || type === "hpSplit"
            ? "Condenser"
            : "Air Handler + Condenser Package"}{" "}
          · {condenser.model}
        </h3>
        <div>
          <img src={condenser.img} alt={condenser.model} />
        </div>
        <div>
          <ul className="condenser">
            {condenser.features.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
        <Icons>
          {condenser.icons.map((item, index) => {
            return <img key={index} src={item} alt="Feature Icon" />
          })}
        </Icons>
      </Grid>
    </Container>
  )
}

export default Two
