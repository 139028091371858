import React from "react"
import { Container, Grid, Table } from "./styled"

const Specs = ({ type, handler, condenser }) => {
  return (
    <Container>
      <h2>Specifications & Dimensions</h2>
      <div>
        {(type === "acSplit" || type === "hpSplit") && (
          <Grid className={type}>
            <h3>{`Air Handler · ${handler.model}`}</h3>
            <div>
              <img src={handler.img} alt={handler.model} />
            </div>
            <div>
              <Table>
                <tbody>
                  {handler.specs.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>{`${item.name}:`}</th>
                        <td>{item.value}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Grid>
        )}
        <Grid>
          <h3>
            {type === "acSplit" || type === "hpSplit"
              ? "Condenser"
              : "Air Handler + Condenser Package"}{" "}
            · {condenser.model}
          </h3>
          <div>
            <img src={condenser.img} alt={condenser.model} />
          </div>
          <div>
            <Table>
              <tbody>
                {condenser.specs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th>{`${item.name}:`}</th>
                      <td>{item.value}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Grid>
      </div>
    </Container>
  )
}

export default Specs
