import React, { Fragment } from "react"
import ButtonLink from "../../ButtonLink"
import { Container, Table } from "./styled"
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons"

const Back = ({ data }) => {
  return (
    <Container>
      {data.install ? (
        <Fragment>
          <div>
            <ButtonLink
              to={data.back}
              name="Back to Results"
              icon={faChevronCircleLeft}
            />
          </div>

          <div className="table">
            <Table>
              <tbody>
                <tr>
                  <th>Install Location:</th>
                  <td>{data.install}</td>
                </tr>
                <tr>
                  <th>Type:</th>
                  <td>{data.type}</td>
                </tr>
                <tr>
                  <th>Tonnage:</th>
                  <td>{data.tonnage}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Fragment>
      ) : (
        <div>
          <ButtonLink
            to={data.back}
            name="Search for Units"
            icon={faChevronCircleLeft}
          />
        </div>
      )}
    </Container>
  )
}

export default Back
