import React from "react"
import {
  fmtCurrency,
  fmtSeer,
  fmtTonnage,
  fmtBtu,
} from "../../../utils/helpers"
import One from "./One"
import Two from "./Two"
import { Container } from "./styled"

const Details = ({ data, click }) => {
  return (
    <Container>
      <One
        systemId={data.systemId}
        manufacturer={data.manufacturer}
        seer={fmtSeer(data.seer)}
        tonnage={fmtTonnage(data.tonnage)}
        btu={fmtBtu(data.btu)}
        ahri={data.ahri}
        price={fmtCurrency(data.price)}
        click={click}
      />
      <Two type={data.type} handler={data.handler} condenser={data.condenser} />
      <One
        systemId={data.systemId}
        manufacturer={data.manufacturer}
        seer={fmtSeer(data.seer)}
        tonnage={fmtTonnage(data.tonnage)}
        btu={fmtBtu(data.btu)}
        ahri={data.ahri}
        price={fmtCurrency(data.price)}
        click={click}
      />
    </Container>
  )
}

export default Details
