import styled from "styled-components"

export const Container = styled.div`
  padding: 15px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  & > div:first-child {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: 1px solid #ddd;
    }

    & > span {
      font-size: 1.15rem;
      line-height: 28px;
      font-weight: 600;
      font-family: Muli, sans-serif;
    }

    & > a {
      text-decoration: none;
      color: #154d7c;
      padding-top: 2.5px;
      display: flex;
      align-items: center;

      & > small {
        padding-left: 7.5px;
        font-size: 1rem;
      }
    }
  }

  & > div:last-child {
    flex: 1 1 35%;
    text-align: center;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      flex: 1 1 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      flex: 1 1 100%;
      padding-top: 15px;
    }

    & > span {
      font-size: 1.35rem;
      font-family: Muli, sans-sans-serif;
      font-weight: 600;
    }

    & > small {
      display: block;
      padding-bottom: 7.5px;
    }
  }
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    justify-content: center;
  }

  & > img {
    width: 150px;
  }
`
