import styled from "styled-components"

export const Container = styled.div`
  margin-top: 35px;

  & > div {
    display: flex;
    border: 1px solid #ddd;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`

export const Grid = styled.div`
  padding: 0;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-content: start;

  @media (max-width: 767px) {
    border-left: none;
    border-right: none;
  }

  &.acSplit,
  &.hpSplit {
    border-right: 1px solid #ddd;

    @media (max-width: 767px) {
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
  }

  & > h3 {
    grid-column: span 2;
    text-align: center;
    background-color: #f1f1f1;
    margin: 0;
    margin-bottom: 15px;
    padding: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #ddd;
  }

  & > div {
    padding-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const Table = styled.table`
  width: 100%;

  & th,
  & td {
    border: 1px solid #ddd;
    padding: 7.5px;
    font-size: 0.9rem;
  }

  & th {
    text-align: right;
  }

  & td {
    font-family: Muli, sans-serif;
    font-size: 0.8rem;
  }
`
