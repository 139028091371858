import React from "react"
import { Btn } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = ({ item, click, name, icon }) => {
  return (
    <Btn type="button" onClick={e => click(e, item)}>
      <span>
        <FontAwesomeIcon icon={icon} size="lg" />
        <span> {name} </span>
      </span>
    </Btn>
  )
}

export default Button
