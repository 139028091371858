import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(props => <Link {...props} />)`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  background: #6daa29;
  padding: 12px 18px;
  border: 1px solid #6daa29;
  border-radius: 8px;
  color: #fff;
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 16px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: background 0.5s ease, color 0.5s ease;
  outline: none;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: #fff;
    background: #7fc630;
    border-color: #7fc630;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      padding-left: 7.5px;
    }
  }
`
