import styled from "styled-components"

export const Container = styled.div`
  margin-top: 35px;

  & > div {
    display: flex;
    border: 1px solid #ddd;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`

export const Grid = styled.div`
  width: 100%;
  padding: 0;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  &.acSplit,
  &.hpSplit {
    border-right: 1px solid #ddd;

    @media (max-width: 767px) {
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
  }

  & > h3 {
    text-align: center;
    background-color: #f1f1f1;
    margin: 0;
    margin-bottom: 15px;
    padding: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #ddd;
  }

  & > div {
    align-content: flex-start;
  }
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 0;
  padding-bottom: 15px;

  & > img {
    width: 100px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
`

export const Description = styled.div`
  padding-left: 15px;
  padding-right: 15px;

  & > p {
    font-size: 0.95rem;
  }
`

export const Buttons = styled.div`
  align-self: end;
  display: flex;
  align-items: center;
  margin: 0 15px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  & > a {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    background: #ffffff;
    padding: 12px 18px;
    border: 1px solid #6daa29;
    border-radius: 8px;
    color: #6daa29;
    font-family: "Muli", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 16px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: background 0.5s ease, color 0.5s ease;
    outline: none;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: #fff;
      background: #7fc630;
      border-color: #7fc630;
    }

    &:first-child {
      margin-right: 7.5px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &:last-child {
      margin-left: 7.5px;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
`
