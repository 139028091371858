import styled from "styled-components"
import { Table as BaseTable } from "../../../elements/Layout/styled"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.25fr 0.5fr;
  grid-gap: 25px;
  margin-bottom: 25px;
  align-items: center;

  @media screen and (max-width: 1240px) {
    grid-template-columns: 0.55fr 1fr;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: 0.5fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  & > div {
    text-align: center;
  }

  & > div.hidden {
    display: none;
  }

  & > div.table {
    @media screen and (max-width: 1023px) {
      grid-column: 2;
      grid-row: 1 / 1;
    }
    @media screen and (max-width: 768px) {
      grid-column: 1;
      grid-row: 1 / 1;
    }
  }
`

export const Table = styled(BaseTable)`
  & th,
  & td {
    font-size: 0.85rem;
    padding: 5px 7.5px;
  }

  & th {
    text-align: right;
  }

  & td {
    text-align: left;
  }
`
