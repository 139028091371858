import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const Grid = styled.div`
  padding: 0;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-content: start;

  @media (max-width: 767px) {
    border-left: none;
    border-right: none;
  }

  & > div {
    @media (max-width: 767px) {
      grid-column: span 2;
    }
  }

  &.acSplit,
  &.hpSplit {
    border-right: 1px solid #ddd;

    @media (max-width: 767px) {
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
  }

  & > h3 {
    grid-column: span 2;
    text-align: center;
    background-color: #f1f1f1;
    margin: 0;
    margin-bottom: 15px;
    padding: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #ddd;
  }

  & > div:nth-child(3) {
    & > ul {
      margin: 0;
      padding: 0;
      font-size: 0.85rem;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 5px;
      padding-right: 25px;
      list-style-type: none;

      &.condenser {
        padding-left: 25px;
      }

      @media (max-width: 767px) {
        padding-left: 25px;
      }

      & > li {
        border-bottom: 1px solid #ddd;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        line-height: 19px;
      }

      & > li:last-child {
        border-bottom: none;
      }
    }
  }
`

export const Icons = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
  padding-bottom: 15px;

  & > img {
    width: 100px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
`
