import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import { Container, Grid, Icons, Description, Buttons } from "./styled"

const Warranty = ({ type, handler, condenser }) => {
  return (
    <Container>
      <h2>Warranty Information</h2>
      <div>
        {(type === "acSplit" || type === "hpSplit") && (
          <Grid className={type}>
            <h3>{`Air Handler · ${handler.model}`}</h3>
            <div>
              <Icons>
                {handler.icons.map((item, index) => {
                  return <img key={index} src={item} alt="Warranty Icon" />
                })}
              </Icons>
              <Description>
                {handler.description.map((item, index) => {
                  return <p key={index}>{item}</p>
                })}
              </Description>
            </div>
            <Buttons>
              {handler.buttons.map((item, index) => {
                return (
                  <OutboundLink key={index} href={item.url} target="_blank">
                    {item.name}
                  </OutboundLink>
                )
              })}
            </Buttons>
          </Grid>
        )}
        <Grid>
          <h3>
            {type === "acSplit" || type === "hpSplit"
              ? "Condenser"
              : "Air Handler + Condenser Package"}{" "}
            · {condenser.model}
          </h3>
          <div>
            <Icons>
              {condenser.icons.map((item, index) => {
                return <img key={index} src={item} alt="Warranty Icon" />
              })}
            </Icons>
            <Description>
              {condenser.description.map((item, index) => {
                return <p key={index}>{item}</p>
              })}
            </Description>
          </div>
          <Buttons>
            {condenser.buttons.map((item, index) => {
              return (
                <OutboundLink key={index} href={item.url} target="_blank">
                  {item.name}
                </OutboundLink>
              )
            })}
          </Buttons>
        </Grid>
      </div>
    </Container>
  )
}

export default Warranty
