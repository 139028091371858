import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import {
  API_URL,
  getOrder,
  getCart,
  setCart,
  fmtSeer,
  fmtTonnage,
  fmtBtu,
  getLocationPrice,
} from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Back from "../components/system/Back"
import Details from "../components/system/Details"
import Warranty from "../components/system/Warranty"
import Specs from "../components/system/Specs"
import { Main, MainContainer } from "../components/buy/styled"

const System = ({ data }) => {
  const [loading, setLoading] = useState(true)
  const [criteria, setCriteria] = useState({})

  useEffect(() => {
    let type = ""
    let tonnage = ""
    if (getOrder() && getOrder().type && getOrder().tonnage) {
      type = getOrder().type
      tonnage = getOrder().tonnage
    }
    init(type, tonnage, data.one.edges[0].node.systemId)
  }, [data])

  const init = async (type, tonnage, systemId) => {
    const url = new URL(`${API_URL}/systemInit`)
    const params = { type, tonnage, systemId }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setCriteria({
          back:
            type && tonnage
              ? `/buy/results?type=${type}&tonnage=${tonnage}`
              : `/buy/1`,
          install: getOrder().zip
            ? `${getOrder().city}, ${getOrder().st} ${getOrder().zip}`
            : ``,
          location: getOrder().location,
          type: json.fmtType,
          tonnage: json.fmtTonnage,
          warranty: json.warranty,
          specs: json.specs,
        })
      }
    } catch (error) {
      console.log("error")
    }
    setLoading(false)
  }

  const handleATC = (e, itemData) => {
    e.preventDefault()
    let cart = getCart()
    let newItem = {
      product: "system",
      systemId: data.one.edges[0].node.systemId,
      qty: 1,
      item: `Franklin ${data.one.edges[0].node.fmtType} ${data.one.edges[0].node.ahri}`,
      subitem: `${fmtSeer(data.one.edges[0].node.seer)} / ${fmtTonnage(
        data.one.edges[0].node.tonnage
      )} / ${fmtBtu(data.one.edges[0].node.btu)}`,
      price: data.one.edges[0].node.price,
      extras: [
        {
          type: "thermostat",
          name: "Honeywell T6 Thermostat",
          price: 0,
        },
        {
          type: "other",
          name: criteria.location
            ? `${criteria.location} Air Handler Install`
            : "Unit Install",
          price: getLocationPrice(criteria.location),
        },
      ],
    }
    /*
    {
      type: "promo",
      name: "First Year Maintenance Plan",
      price: 0,
    },
    */
    cart.push(newItem)
    setCart(cart)
    navigate("/cart")
  }

  return (
    <Layout>
      <Helmet
        name={`Franklin ${data.one.edges[0].node.fmtType} ${data.one.edges[0].node.ahri}`}
        description={`System, specification and warranty details for Franklin ${data.one.edges[0].node.seer} SEER ${data.one.edges[0].node.fmtType} ${data.one.edges[0].node.ahri}.`}
        slug={data.one.edges[0].node.slug}
      />
      <Header />
      <PageHdr title={`Franklin ${data.one.edges[0].node.fmtType}`} />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              {!loading && (
                <Back
                  data={{
                    back: criteria.back,
                    install: criteria.install,
                    type: criteria.type,
                    tonnage: criteria.tonnage,
                  }}
                />
              )}
              <Details data={data.one.edges[0].node} click={handleATC} />
              {!loading && (
                <Warranty
                  type={criteria.warranty.type}
                  handler={criteria.warranty.handler}
                  condenser={criteria.warranty.condenser}
                />
              )}
              {!loading && (
                <Specs
                  type={criteria.specs.type}
                  handler={criteria.specs.handler}
                  condenser={criteria.specs.condenser}
                />
              )}
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default System

export const query = graphql`
  query SystemBySlug($slug: String!) {
    one: allACsystems(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          systemId
          slug
          manufacturer {
            name
            logo
          }
          type
          fmtType
          ahri
          seer
          tonnage
          btu
          price
          handler {
            img
            model
            features
            icons
          }
          condenser {
            img
            model
            features
            icons
          }
        }
      }
    }
  }
`
