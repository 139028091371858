import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
} from "../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)``
