import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import Button from "../../../Button"
import { Container, Logo } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCartPlus, faFilePdf } from "@fortawesome/pro-light-svg-icons"

const One = ({
  systemId,
  manufacturer,
  seer,
  tonnage,
  btu,
  ahri,
  price,
  click,
}) => {
  return (
    <Container>
      <div>
        <Logo>
          <img src={manufacturer.logo} alt={manufacturer.name} />
        </Logo>
        <span>{`${seer} / ${tonnage} / ${btu}`}</span>
        <OutboundLink
          href={`https://tjpc.com/pdf/${systemId}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFilePdf} />
          <small>{`AHRI #${ahri}`}</small>
        </OutboundLink>
      </div>
      <div>
        <span>{price}</span>
        <br />
        <small>(includes professional installation)</small>
        <Button item={{}} click={click} name="Add to Cart" icon={faCartPlus} />
      </div>
    </Container>
  )
}

export default One
